var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", disabled: _vm.disabled },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b({ staticClass: "w-100" }, "div", attrs, false),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.deleteDialog,
        callback: function ($$v) {
          _vm.deleteDialog = $$v
        },
        expression: "deleteDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("delete")]),
              _vm._v(" Delete "),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-3" }, [
            _vm._v(" " + _vm._s(_vm.warningText()) + " "),
            _c(
              "ul",
              { staticClass: "mt-3" },
              _vm._l(_vm.selected, function (app) {
                return _c("li", { key: app.aid }, [_vm._v(_vm._s(app.long_id))])
              }),
              0
            ),
          ]),
          _c(
            "v-card-actions",
            [
              _vm.fetchingDeids
                ? _c(
                    "div",
                    { staticClass: "caption" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          indeterminate: "",
                          color: "primary",
                          size: "16",
                        },
                      }),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v("Fetching application data"),
                      ]),
                    ],
                    1
                  )
                : _vm.envs > 0
                ? _c("v-checkbox", {
                    attrs: { color: "error" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "caption" }, [
                              _vm._v("Also delete exported versions"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.deleteExportedApps,
                      callback: function ($$v) {
                        _vm.deleteExportedApps = $$v
                      },
                      expression: "deleteExportedApps",
                    },
                  })
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    text: "",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.deleteDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    text: "",
                    loading: _vm.loading,
                    disabled: _vm.fetchingDeids,
                  },
                  on: { click: _vm.deleteApps },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }